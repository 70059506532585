// Borders

$border-sm:1px;
$border-md:2.5px;

// Radius

$radius-sm:12px;
$radius-md: $radius-sm * 2;
$radius-lg: $radius-sm * 3;

$input:4px;

// IMG

$img-rd:8px;
@import "../../global-styles/index.scss";

.mobile-nav-bar {
  display: none;
}

@media screen and (max-width: 800px) {
  // if mobile is 800px or less this will happen.
  .mobile-nav-bar {
    @include flex-row-justify-align(100px, 100%, center, center);
    background-color: transparent;
    color: white;
  }

  .mobile-menu-container {
    @include flex-column-justify-align(150px, 100%, center, center);
    color: white;
  
  }

  #hamburger-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50%;
    // border: solid white 1px;
  }
  #hamburger-container {
    display: flex;
    height: 100%;
    width: 90%;
    justify-content: space-between;
  }
  #img-container {
    display: flex;
    width: 20%;//65px;
    justify-content: center;
    align-items: center;
    height: 100%;

    img {
      display: flex;
      filter: invert(100%);
      height: 70%;
      //width: 100%;
    }
  }

  .mobile-logo {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
  }

  #logo-container {
    display: flex;
    width: 100%;
    justify-content: start;

    
    img {
      display: flex;
      width: 50px;

    }
  }

  .ss-logo-title {
    @include flex-column-justify-align(100%, 100%, center, center);
    // border: solid red 1px;
    // font-size: 1.25em;
    letter-spacing: 2px;

  }
}

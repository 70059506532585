// Text-Letters
$letter-xxxsm: 1px;
$letter-xsm:2px;
$letter-md: 5px;

// Font-Weights

$font-md: 100;

// Widths

$text-lg: 120px;

// Heights

// Gaps
$input-gap-column-sm: 10px;
$input-gap-column-md: $input-gap-column-sm * 2;

@import "../../global-styles/index.scss";

.ss-industry-info {
  @include flex-column-justify-align(auto, 100%, center, center);
}

.ss-industry-info__welcome {
  @include flex-row-justify-align(calc(100vh - 150px), 100%, center, center);
}

.ss-industry-info__welcome__copy-container {
  @include flex-column-justify-align(auto, 100%, center, center);
  order: 1;
}

.ss-industry-info__welcome__copy-container__copy {
  @include flex-column-justify-align(auto, 100%, center, center);
  font-size: 3em;
}

.ss-industry-info__welcome__card {
  @include flex-column-justify-align(auto, 100%, center, center);
  // border:solid red 1px;
  order: 2;
  img {
    border-radius: $img-rd;
  }
}

.ss-industry-info__perks {
  @include flex-column-justify-align(auto, 100%, center, center);
  background-color: white;
  padding: 50px 0px;
}

.ss-industry-info__industry__card {
  @include flex-column-justify-align(auto, auto, center, center);
  // border:solid red 1px;
  padding: 50px 0px;
  order: 1;
  img {
    border-radius: $img-rd;
  }
}

.ss-industry-info__perks__title {
  @include flex-column-justify-align(auto, 100%, center, center);
  order: 2;

  div {
    color: $main-blue;
    font-size: 3em;
    font-weight: 200;
  }

  div:nth-child(2) {
    font-size: 1em;
  }
}

.ss-industry-info__perks__attributes {
  @include flex-column-justify-align(auto, 100%, center, center);
  order: 3;
  font-weight: 200;
  padding: 50px 0px;
}

.ss-industry-info__perks__attributes__attribute {
  @include flex-row-justify-align(auto, 100%, center, center);
  color: black;
}

.ss-industry-info__perks__attributes__attribute__container {
  @include flex-row-justify-align(auto, auto, center, center);
  border-bottom: solid $main-blue 1px;
  padding: 20px 0px;
  min-width: 800px;
  max-width: 800px;
}

.ss-industry-info__perks__attributes__attribute__title {
  @include flex-row-justify-align(auto, 50%, left, center);
  font-size: 2em;
}

.ss-industry-info__perks__attributes__attribute__content {
  @include flex-row-justify-align(auto, 50%, left, center);
  text-align: left;
}

.ss-industry-info__apply{
  @include flex-row-justify-align(50vh, 100%, center, center);

}


@media screen and (max-width: 1200px) {
  .ss-industry-info__welcome__copy-container__copy {
    @include flex-column-justify-align(auto, 100%, center, center);
    font-size: 2em;
  }
}

@media screen and (max-width: 800px) {
  .ss-industry-info {
    @include flex-column-justify-align(auto, 100%, start, center);

  }
  .ss-industry-info__welcome {
    @include flex-column-justify-align(calc(100vh - 150px), 100%, start, center);
    
  }

  .ss-industry-info__welcome__copy-container__copy {
    @include flex-column-justify-align(calc(50vh - 150px), 100%, center, center);
    font-size: 2em;

  }

  .ss-industry-info__welcome__card {
    @include flex-column-justify-align(auto, 100%, center, center);
    // border:solid red 1px;
    // order: 1;
    img {
      border-radius: $img-rd;
    }
  }

  .ss-industry-info__industry__card {
    @include flex-column-justify-align(50%, auto, center, center);
    // border:solid red 1px;
    // order: 1;
    padding: 25px;

    img {
      @include flex-column-justify-align(auto, auto, center, center);
      border-radius: $img-rd;
    }
  }

  .ss-industry-info__perks__title {
    div {
      font-size: 2em;
    }
  }

  .ss-industry-info__perks__attributes {
    @include flex-column-justify-align(auto, 100%, center, center);
    order: 3;
    font-weight: 200;
    padding: 10px 0px;
  }

  .ss-industry-info__perks__attributes__attribute {
    @include flex-row-justify-align(auto, 100%, center, center);
    color: black;
  }

  .ss-industry-info__perks__attributes__attribute__container {
    @include flex-row-justify-align(auto, auto, space-between, center);
    border-bottom: solid $main-blue 1px;
    padding: 20px 0px;
    min-width: 400px;
    max-width: 400px;
  }

  .ss-industry-info__perks__attributes__attribute__title {
    @include flex-row-justify-align(100%, 40%, left, center);
    font-size: 1.5em;
  }

  .ss-industry-info__perks__attributes__attribute__content {
    @include flex-row-justify-align(auto, 60%, left, center);
    text-align: left;
    font-size: 0.75em;
  }
}

@import "../../global-styles/index.scss";
.ss-header {
  @include flex-row-justify($nav-bar-hgt, 100%, center);
  align-items: center;
}

.ss-header__logo-container {
  @include flex-row-justify-align(100%, 100px, center, center);

  img {
    @include flex-row-justify-align(80px, 40px, center, center);
    min-width: 45px;
  }
}

.ss-header__text {
  font-weight: 100;
  letter-spacing: 5px;
}

.ss-header-link-container {
  @include flex-row-justify-align(auto, 200px, center, center);
  // padding: 20px;
  font-size: 0.75em;

  .ss-header-link-container__links {
    @include flex-row-justify-align(auto, 200px, center, center);
    gap:20px;
    a {
      transition: 0.25s ease-in-out;
      border-bottom: solid $main-background 1px;
      text-decoration: none;
      color: white;
     // width:50%;
      letter-spacing: 1px;

    }
    a:hover {
      color: $main-blue;
      cursor: pointer;
      border-bottom: solid $main-blue 1px;
    }
  }
}
.start {
 justify-content: start !important;
}

.end {
  justify-content: end !important;
}

@media screen and (max-width: 800px) {
  .ss-header {
    display: none;
  }
}

@import "../../../global-styles/index.scss";

.ss-user-dashboard-menu {
  @include flex-column-justify-align(100%, 25%, center, center);
}

.ss-user-dashboard-menu-options {
  @include flex-column-justify-align(90%, 90%, start, center);
  gap: 30px;
}

.ss-menu-option {
  @include flex-row-justify(auto, 100%, left);
  font-size: 3em;
  transition: 0.25s ease-in-out;
  font-weight: 200;
  text-align: left;
}


.ss-menu-option:hover{
    opacity: 50%;
    cursor: pointer;
    color: $main-blue;
}

@media screen and (max-width: 1400px) {
  .ss-menu-option {
    font-size: 2.5em;

  }
}

@media screen and (max-width: 1200px) {
  .ss-menu-option {
    font-size: 2em;
 
  }
}

@media screen and (max-width: 1000px) {
  .ss-menu-option {
    font-size: 1.75em;
 
  }
}

@media screen and (max-width: 800px) {
  .ss-user-dashboard-menu {
    display: none;
  }
}


.active{
  color:$main-blue
}
@import "../../global-styles/index.scss";

.ss-application__main-content {
  @include flex-column-justify-align(100%, 100%, start, center);
}

.ss-application__main-content__main {
  @include flex-column-justify-align(100%, 100%, center, center);
}

.ss-application__main-content__main__ad-copy-container {
  @include flex-column-justify-align(100%, 100%, center, center);
  font-size: 1.25em;
  letter-spacing: $letter-md;
}

.ss-application__form-dialogue {
  font-size: 0.75em;
  color: $main-blue;
}

@media screen and (max-width: 800px) {
  .ss-application__main-content {
    @include flex-column-justify-align(100%, 100%, start, center);
  }

  .ss-application__main-content__main {
    @include flex-column-justify-align(100%, 100%, center, center);
  }

  .ss-application__main-content__main__ad-copy-container {
    @include flex-column-justify-align(100%, 100%, center, center);
    font-size: 1em;
    letter-spacing: $letter-md;
  }
}

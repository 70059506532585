@import "../../global-styles/index.scss";

.ss-user-dashboard {
  @include flex-row-justify-align(
    calc(100vh - $nav-bar-hgt),
    100%,
    center,
    center
  );
  overflow: hidden;
}

.ss-user-dashboard-content {
  @include flex-column-justify-align(100%, 75%, center, center);
}

// BILLING OPTION
.ss-user-dashboard__billing {
  @include flex-row-justify-align(90%, 100%, center, center);
}

.ss-user-dashboard__billing__container {
  @include flex-row-justify-align(100%, 100%, space-around, center);
  border: solid white 1px;
}

@media screen and (max-width: 800px) {
  .ss-user-dashboard {
    @include flex-column-justify-align(auto, 100%, space-between, center);
    gap: 25px;
    padding: 25px 0px;
  }

  .ss-user-dashboard-content {
    @include flex-column-justify-align(100%, 90%, center, center);
    gap: 25px;
  }
}

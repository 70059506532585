/*
 * Input Fields
 */

// Heights
$input-sm: 16px;
$input-md: $input-sm * 1.5;
$input-lg: $input-sm * 2;
$nav-bar-hgt:120px;

// Widths

$input-wd-sm: 175px;



/*
 * Buttons
 */

// Widths
$btn-wd-md: 100px;

// Heights

$btn-ht-md: 40px;

// Cards

$card-lg-ht: 50%;
$card-lg-wd: 60%;

$card-sm-ht: 20%;
$card-sm-wd: 30%;


// ANCHOR

$anchor-padding-lg:25px;
$anchor-ft-wgt:200;
$anchor-opacity:50%;
$anchor-transition-time: 0.25s;
@import "../../../global-styles/mixins.scss";
@import "../../../global-styles/colors.scss";

.ss-apply-overlay-container{
  display: none;
  height: 100vh;
  width: 100vw;
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
}

.ss-apply-overlay {
  @include flex-column-justify-align(auto, auto, start, center);
  padding:50px;
  display: none;
  position: absolute;
  color:$main-blue;
  z-index: 2;
  font-weight: 300;
  font-size: 1.5em;
  min-height: 300px;
  min-width: 350px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 95%;
  border-radius: 8px;
  padding: 14px;
}

.display-overlay {
  display: flex;
  background-color: black !important;
}

.display-error {
  display: flex;
  background-color: black !important;
  border:solid $main-blue 1px;
}
.display{
  display: flex;
}

.ss-apply-overlay__close-container {
  @include flex-row-justify-align(auto, 100%, flex-end, center);

  img {
    transition: 0.25s ease-in-out;
  }

  img:hover {
    cursor: pointer;
    scale: 1.05;
  }
}

.ss-apply-overlay__content {
  @include flex-row-justify-align(auto, 100%, center, center);
  padding:50px 0px;

}

@media screen and (max-width: 800px) {
  .ss-apply-overlay {
    position: absolute;
    min-width: 300px;

    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $main-blue;
    opacity: 80%;
  }

  .ss-apply-overlay__content {
    padding:25px 0px;
  
  }
}

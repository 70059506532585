@import "../../../global-styles/index.scss";

.ss-loading-backdrop {
  display: flex;
  height: 100vh;
  width: 100vw;
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
}

.ss-loading-overlay-container {
  @include flex-column-justify-align(auto, 100%, center, center);
}

.ss-loading-overlay-container__spinner {
  position: absolute;
  top: calc(50% - 50px);
  left: calc(50% - 53px);
  // left: calc(50% - 50px);
  right: calc(50% - 50px);
  //   transform: translate(-50%, -50%);
  min-height: 100px;
  min-width: 100px;
  border-radius: 50%;
  border: solid 3px;
  border-color: $main-blue transparent $main-blue transparent;
  animation: spin 1s linear infinite;
}

.ss-loading-overlay-container__loading {
  @include flex-column-justify-align(auto, auto, center, center);
  text-align: center;
  font-weight: 300;
  //border:solid red 1px;
}

@import "../../../global-styles/index.scss";

.ss-payment-form-container{
    background-color: white;
    padding:25px;
    border-radius: 8px;
}
.ss-payment-form-container__title{
    color:black;
    font-weight:300;

}

.ss-payment-form-container__amount{
    @include flex-row-justify-align(auto,100%, space-between, center);
    color:black;
    padding:25px 0px;
    font-weight:200;

}

@media screen and (max-width:800px) {
    .ss-payment-form-container{
        @include flex-column-justify-align(100%, 100%, center, center);

      
    }

}
@import "../../../global-styles/index.scss";

.ss-confirmation-dialog-overlay {
    display: flex;
    height: 100vh;
    width: 100vw;
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
}

.ss-confirmation-dialog {
    @include flex-column-justify-align(auto, 20%, space-between, center);
    display: flex;
    z-index: 2;
    position: absolute;
    background-color: $main-blue;
    color: black;
    font-weight: 200;
    padding: 25px;
    border-radius: 8px;
    gap: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.ss-confirmation-dialog__title {
    font-size: 1.25em;
    font-weight: 300;
}

.ss-confirmation-dialog__container {
    @include flex-column-justify-align(80%, 100%, space-between, center);
    padding: 25px 0px;
    gap: 25px;
}

.ss-confirmation-dialog__container__buttons {
    @include flex-row-justify-align(auto, 80%, space-between, center);
}

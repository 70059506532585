@import "../../global-styles//index.scss";

.ss-about__main-content {
  @include flex-column-justify-align(100%, 100%, center, center);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ss-about__main-content__main {
  @include flex-column-justify-align(100%, 100%, center, center);
  font-size: 2em;
  letter-spacing: $letter-md;
}
.ss-about__main-content__main__ad-copy-container {
  @include flex-column-justify-align(100%, 100%, center, center);
  letter-spacing: $letter-md;
}

.highlight {
  color: $main-blue;
  border: none;
  text-decoration: none;
}

@media screen and (max-width: 800px) {
  .ss-about__main-content__main {
    @include flex-column-justify-align(100%, 100%, center, center);
    font-size: 1.5em;
    letter-spacing: $letter-md;
  }

  .highlight {
    // padding: 25px;
    color: $main-blue;
    border: none;
    text-decoration: none;
  }
}
